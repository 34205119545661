<template>
    <div class="restaurants-filter">
        <multiselect class="restaurants-filter__select restaurants-filter__select_multiple"
            v-model="selectedRestaurants"
            :options="allRestaurants"
            @close="setSelectedRestaurantsToReport"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="false"
            :showLabels="false"
            placeholder="Выберите рестораны"
            label="title"
            track-by="id"
        >
            <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
                    <template v-if="values.length === allRestaurants.length">Все рестораны</template>
                    <template v-else>Рестораны: {{values.length}}</template>
                </span>
            </template>
        </multiselect>
        <button class="restaurants-filter__button restaurants-filter__button_select"
            v-if="selectedRestaurants.length !== allRestaurants.length"
            @click="selectAllReportRestaurants"
        >
            Выбрать все
        </button>
        <button class="restaurants-filter__button restaurants-filter__button_clear"
            v-if="selectedRestaurants.length !== 0"
            @click="clearReportRestaurants"
        >
            Очистить
        </button>
    </div>
</template>

<script>
    import { mapMutations, mapState, mapGetters } from "vuex";

    export default {
        name: "RestaurantsFilter",
        data() {
            return {
                selectedRestaurants: [],
            };
        },
        computed: {
            ...mapState({
                reportRestaurants: state => state.report.restaurants,
            }),
            ...mapGetters({
                allRestaurants: "userRestaurantsList"
            }),
        },
        methods: {
            ...mapMutations([
                "setReportParameter",
            ]),
            setSelectedRestaurantsToReport() {
                this.setReportParameter({
                    parameter: "restaurants",
                    value: this.selectedRestaurants
                });
            },
            clearReportRestaurants() {
                this.selectedRestaurants = [];
                this.setSelectedRestaurantsToReport();
            },
            selectAllReportRestaurants() {
                this.selectedRestaurants = this.allRestaurants;
                this.setSelectedRestaurantsToReport();
            }
        },
        created() {
            this.selectedRestaurants = this.reportRestaurants;
        }
    }
</script>

<style lang="scss">
    .restaurants-filter {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: center;
    }
    .restaurants-filter__select {
        min-width: 240px;
        height: 40px;
        padding: 0;
        border-color: $gray-line;
        border-radius: 4px;

        & .multiselect__tags-wrap {
            display: none !important;
        }
    }
    .restaurants-filter__button {
        place-self: center;
        padding: 5px 10px;
        border-radius: 4px;
        color: #fff;

        &_select {
            background-color: $green-primary;
        }

        &_clear {
            background-color: $red-secondary;
        }
    }
</style>
