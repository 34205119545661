<template>
    <section class="income-settings">
        <h2 class="income-settings-heading sr-only">Настройки отчета "Доходы"</h2>
        <form class="income-settings__form" @submit.prevent>
            <fieldset class="income-settings__fieldset income-settings__fieldset_dateranges">
                <daterange-picker class="income-settings__daterange"
                    v-model="reportDaterangeModel"
                    name="reportDaterange"
                >
                    Период отчета:
                </daterange-picker>
                <daterange-picker class="income-settings__daterange"
                    v-if="modeId === 'compareOtherPeriod'"
                    v-model="compareDaterangeModel"
                    name="compareOtherPeriod"
                >
                    Период сравнения:
                </daterange-picker>
                <daterange-picker class="income-settings__daterange"
                    v-if="modeId === 'dynamic'"
                    v-model="dynamicDaterangeModel"
                    name="dynamic"
                    :minDate="reportDaterange.start"
                    :maxDate="reportDaterange.end"
                >
                    Период динамики:
                </daterange-picker>
            </fieldset>
            <fieldset class="income-settings__fieldset income-settings__fieldset_selects">
                <select class="income-settings__select"
                    v-model="modeModel"
                >
                    <option class="income-settings__option"
                        v-for="mode in modes"
                        :key="mode.id"
                        :value="mode.id"
                    >
                        {{mode.title}}
                    </option>
                </select>
                <restaurants-filter v-if="showRestaurantsFilter" />
            </fieldset>
        </form>
    </section>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import DaterangePicker from "@/components/DaterangePicker";
    import RestaurantsFilter from "@/components/RestaurantsFilter";

    export default {
        name: "IncomeSettings",
        components: {
            DaterangePicker,
            RestaurantsFilter,
        },
        props: {
            showRestaurantsFilter: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.income.compareDaterange,
                dynamicDaterange: state => state.income.dynamicDaterange,
                modes: state => state.income.modes,
                modeId: state => state.income.modeId,
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(value) {
                    this.setReportParameter({ parameter: "daterange", value });
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(value) {
                    this.setIncomeParameter({ parameter: "compareDaterange", value });
                }
            },
            dynamicDaterangeModel: {
                get() {
                    return this.dynamicDaterange;
                },
                set(value) {
                    this.setIncomeParameter({ parameter: "dynamicDaterange", value });
                }
            },
            modeModel: {
                get() {
                    return this.modeId;
                },
                set(value) {
                    this.setIncomeParameter({ parameter: "modeId", value });
                }
            },
        },
        methods: {
            ...mapMutations([
                "setReportParameter",
                "setIncomeParameter",
            ]),
        }
    };
</script>

<style lang="scss">
    .income-settings {
        margin-bottom: 15px;
    }
    .income-settings__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }

        &_dateranges {
            margin-bottom: 15px;
        }
    }
    .income-settings__select {
        min-width: 240px;
        height: 40px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;

        &_multiple {
            padding: 0;

            & .multiselect__tags-wrap {
                display: none !important;
            }
        }
    }
    .income-settings__button {
        place-self: center;
        padding: 5px 10px;
        border-radius: 4px;
        color: #fff;

        &_select {
            background-color: $green-primary;
        }

        &_clear {
            background-color: $red-secondary;
        }
    }
</style>
