<template>
    <span class="percentage">{{percentage}}</span>
</template>

<script>
    export default {
        name: "Percentage",
        props: {
            part: {
                type: Number,
                required: true
            },
            whole: {
                type: Number,
                required: true
            },
            filter: {
                type: String,
                default: "percentFloatFormat"
            }
        },
        computed: {
            percentage() {
                return this.$options.filters[this.filter](this.whole !== 0 ? (this.part / this.whole) : 0);
            }
        }
    }
</script>

<style lang="scss">
    .percentage {
        color: mediumpurple;
        font-size: 0.75em;
    }
</style>
