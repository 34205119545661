<template>
    <div class="income-dynamic-table__box box">
        <table class="income-dynamic-table__table income-dynamic-table__table_striped income-dynamic-table__table_hover">
            <caption class="income-dynamic-table__caption">
                <template v-if="reportRow.link">
                    <router-link class="link link_red"
                        :to="reportRow.link.to"
                        :title="reportRow.link.title"
                    >
                        {{reportRow.caption}}
                    </router-link>
                </template>
                <template v-else>{{reportRow.caption}}</template>
            </caption>
            <col class="income-dynamic-table__column"
                v-for="column in columns"
                :key="column.id"
                :class="`income-dynamic-table__column_${column.id}`"
            >
            <thead>
                <tr class="income-dynamic-table__row income-dynamic-table__row_head">
                    <th class="income-dynamic-table__cell income-dynamic-table__cell_head" scope="col"
                        v-for="column in columns"
                        :key="column.id"
                    >
                        <div class="income-dynamic-table__cell-content income-dynamic-table__cell-content_columns"
                            :class="`income-dynamic-table__cell-content_${column.alignment}`"
                        >
                            <span class="income-dynamic-table__value">{{column.title}}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="income-dynamic-table__row income-dynamic-table__row_body">
                    <td class="income-dynamic-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'income-dynamic-table__cell_title': column.id === 'title' }"
                    >
                        <div class="income-dynamic-table__cell-content income-dynamic-table__cell-content_rows"
                            :class="`income-dynamic-table__cell-content_${column.alignment}`"
                        >
                            <span class="income-dynamic-table__value">
                                <template v-if="column.id === 'title'">Период отчета ({{reportDaterangeLength}} дней):<br></template>
                                {{format(column, reportRow)}}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr class="income-dynamic-table__row income-dynamic-table__row_body">
                    <td class="income-dynamic-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'income-dynamic-table__cell_title': column.id === 'title' }"
                    >
                        <div class="income-dynamic-table__cell-content income-dynamic-table__cell-content_rows"
                            :class="`income-dynamic-table__cell-content_${column.alignment}`"
                        >
                            <span class="income-dynamic-table__value">{{format(column, reportDaterangeAverage)}}</span>
                        </div>
                    </td>
                </tr>
                <tr class="income-dynamic-table__row income-dynamic-table__row_body">
                    <td class="income-dynamic-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'income-dynamic-table__cell_title': column.id === 'title' }"
                    >
                        <div class="income-dynamic-table__cell-content income-dynamic-table__cell-content_rows"
                            :class="`income-dynamic-table__cell-content_${column.alignment}`"
                        >
                            <span class="income-dynamic-table__value">{{format(column, waitingDynamic)}}</span>
                        </div>
                    </td>
                </tr>
                <tr class="income-dynamic-table__row income-dynamic-table__row_body">
                    <td class="income-dynamic-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'income-dynamic-table__cell_title': column.id === 'title' }"
                    >
                        <div class="income-dynamic-table__cell-content income-dynamic-table__cell-content_rows"
                            :class="`income-dynamic-table__cell-content_${column.alignment}`"
                        >
                            <template v-if="dynamicRow.hasOwnProperty(column.id)">
                                <span class="income-dynamic-table__value">
                                    <template v-if="column.id === 'title'">Период динамики ({{dynamicDaterangeLength}} дней):<br></template>
                                    {{format(column, dynamicRow)}}
                                </span>
                                <difference v-if="column.difference && waitingDynamic[column.id] !== 0"
                                    class="income-dynamic-table__value"
                                    :primaryValue="dynamicRow[column.id]"
                                    :compareValue="waitingDynamic[column.id]"
                                    mode="percentage"
                                    :good="column.difference.good"
                                    filter="percentFloatFormat"
                                />
                            </template>
                            <span v-else class="income-dynamic-table__value">нет данных</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Difference from "@/components/Difference";

    export default {
        name: "IncomeDynamicTable",
        components: {
            Difference,
        },
        props: {
            columns: {
                type: Array,
                required: true
            },
            reportRow: {
                type: Object,
                required: true
            },
            dynamicRow: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters([
                "reportDaterangeLength",
                "dynamicDaterangeLength",
            ]),
            reportDaterangeAverage() {
                return Object.fromEntries(Object.entries(this.reportRow).map(([key, value]) => {
                    switch (key) {
                        case "title":
                            value = "Средние дневные значения периода отчета";
                            break;
                        case "delivery":
                        case "hall":
                        case "take":
                        case "pickup":
                        case "total":
                            value =  value / this.reportDaterangeLength;
                            break;
                        default:
                            break;
                    }

                    return [key, value];
                }));
            },
            waitingDynamic() {
                return Object.fromEntries(Object.entries(this.reportDaterangeAverage).map(([key, value]) => {
                    switch (key) {
                        case "title":
                            value = "Ожидаемые значения периода динамики";
                            break;
                        case "delivery":
                        case "hall":
                        case "take":
                        case "pickup":
                        case "total":
                            value =  value * this.dynamicDaterangeLength;
                            break;
                        default:
                            break;
                    }

                    return [key, value];
                }));
            },
        },
        methods: {
            format(column, row) {
                if (!this.$options.filters[column.filter]) {
                    return row[column.id];
                }

                return this.$options.filters[column.filter](row[column.id]);
            }
        },
    };
</script>

<style lang="scss">
    .income-dynamic-table__box {
        padding: 16px;
        overflow-x: auto;
    }
    .income-dynamic-table__table {
        width: 100%;
    }
    .income-dynamic-table__caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .income-dynamic-table__column {
        &_title {
            width: 280px;
        }
    }
    .income-dynamic-table__row {
        &_body {
            .income-dynamic-table__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .income-dynamic-table__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .income-dynamic-table__cell {
        font-size: 20px;
        padding: 12px 8px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_tar {
            text-align: right;
        }
    }
    .income-dynamic-table__cell-content {
        display: grid;
        grid-gap: 5px;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.income-dynamic-table__cell-content_left {
                justify-content: start;
            }

            &.income-dynamic-table__cell-content_right {
                justify-content: end;
                text-align: right;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.income-dynamic-table__cell-content_left {
                justify-items: start;
            }

            &.income-dynamic-table__cell-content_right {
                justify-items: end;
            }
        }
    }
    .income-dynamic-table__value {
        &_icon {
            width: 16px;
            height: 16px;
        }
    }
</style>
